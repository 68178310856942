import React, { useContext } from 'react';

import { useNavigate, useRouteError } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Button } from '@ninox/ninox-components/lib/Button';
import { Icon } from '@ninox/ninox-components/lib/Icon';
import { LocaleContext } from '@ninox/ninox-components/locale';
import { Size } from '@ninox/ninox-components/theme';

import { Code } from '../styles/Code';
import { Container, Header, Text } from './ErrorElement.styles';

type Props = {
    centered?: boolean;
};

export const ErrorElement = ({ centered }: Props) => {
    const navigate = useNavigate();
    const locale = useContext(LocaleContext);
    const error = useRouteError() as Error | undefined;
    const theme = useTheme();

    // propagate authentification errors to the top
    if (error && 'status' in error && error.status === 401) {
        throw error;
    }

    return (
        <Container centered={centered}>
            <Header>
                <Icon icon={{ icon: 'error', color: theme.icon.attention, size: Size.m, filling: 1 }} />
                <span>{locale.errorServerProcessingUnavailable}</span>
            </Header>

            {process.env.MODE !== 'production' ? (
                <>
                    <Text>{error?.message}</Text>
                    {error && Object.keys(error).length > 0 && <Code>{JSON.stringify(error, null, 2)}</Code>}
                </>
            ) : (
                <>
                    <Text>{error?.message}</Text>
                    <Button emphasis="primary" onClick={() => navigate(-1)}>
                        {locale.back}
                    </Button>
                </>
            )}
        </Container>
    );
};
