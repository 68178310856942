import styled from 'styled-components';

import { Spacing } from '@ninox/ninox-components/theme';

export const Content = styled.div`
    & > header {
        margin: ${Spacing.large} 0;
        gap: 0;
        border: none;
    }

    & > * {
        padding: 0;
    }
`;
