import type { RouteObject } from 'react-router-dom';
import { json } from 'react-router-dom';

import { PublicClient } from '../../../../api/public';
import type { UserPaymentInfo } from '../../../../models/UserPaymentInfo';

export type LoaderData = UserPaymentInfo[];

export const loader: RouteObject['loader'] = async ({ params }) => {
    const userId = params.userId || '';
    const payments = await PublicClient.getPayments(userId);

    return json(payments);
};
