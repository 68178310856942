import type { RouteObject } from 'react-router-dom';
import { json } from 'react-router-dom';

import { PublicClient } from '../../../../api/public';
import type { UserPlanInfo } from '../../../../models/UserPlanInfo';

export type LoaderData = UserPlanInfo;

export const loader: RouteObject['loader'] = async ({ params }) => {
    const userId = params.userId || '';
    const planInfo = await PublicClient.getPlanInfo(userId);

    return json(planInfo);
};
