import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { loader as loaderDeploymentStatus } from './loader/deploymentStatus.loader';
import { loader as loaderMaintenanceInfo } from './loader/maintenanceInfo.loader';
import { loader as loaderVersions } from './loader/versions.loader';
import { Layout } from './Maintenance.layout';
import { action, SystemVersion } from './SystemVersion';

export const Maintenance = (isOnPremise: boolean) =>
    ({
        element: <Layout />,
        id: 'maintenance',
        loader: loaderMaintenanceInfo,
        children: [
            {
                index: true,
                path: '*',
                errorElement: <ErrorElement />,
                lazy: () => import(/* webpackChunkName: "MaintenanceOverview" */ './Overview')
            },
            {
                path: 'version',
                action,
                loader: loaderVersions,
                errorElement: <ErrorElement />,
                element: <SystemVersion isOnPremise={isOnPremise} />
            },
            {
                path: 'status',
                loader: loaderDeploymentStatus
            }
        ]
    }) satisfies RouteObject;
