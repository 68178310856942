import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

import { fromFormData } from '@ninox/ninox-components/utils/formData';

import { PublicClient } from '../../../api/public';

export type ActionResult = {
    available: boolean;
    domain?: string;
};

export const action = async function ({ request }) {
    const formData = await request.formData();
    const { vat } = fromFormData(formData);
    const vatCountry = `${vat}`.substring(0, 2);
    const vatNumber = `${vat}`.substring(2);

    if (vatCountry && vatNumber) {
        const res = await PublicClient.validateVat(vatCountry, vatNumber);

        return json(res);
    }

    return json({ isValid: false });
} satisfies ActionFunction;
