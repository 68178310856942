import React from 'react';

import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ErrorElement } from '../../../../shared/ErrorElement';
import { action as actionCheckDomain } from './CheckDomain.action';
import { action as actionCheckVat } from '../CheckVat.action';
import { Layout } from './CreateCloud.layout';
import { loader } from './CreateCloud.loader';
import { action as actionHostingRegions } from './HostingRegions.action';

export const CreateCloud = {
    element: <Layout />,
    loader,
    shouldRevalidate: () => false,
    children: [
        {
            index: true,
            path: 'domain',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Domain" */ './Domain')
        },
        {
            path: 'billing',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Billing" */ './Billing')
        },
        {
            path: 'summary',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Summary" */ './Summary')
        },
        {
            path: 'confirmation/:domain',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Confirmation" */ './Confirmation')
        },
        {
            path: '*',
            element: <Navigate replace to="domain" />
        },

        {
            path: 'hosting-regions',
            action: actionHostingRegions
        },
        {
            path: 'check-domain',
            action: actionCheckDomain
        },
        {
            path: 'check-vat',
            action: actionCheckVat
        }
    ]
} satisfies RouteObject;
