import type { RouteObject } from 'react-router-dom';
import { defer } from 'react-router-dom';

import { PublicClient } from '../../../../api/public';
import type { CompanyInfo } from '../../../../models/ManageClouds/CompanyInfo';

export type LoaderData = {
    companyInfo: CompanyInfo;
};

export const loader: RouteObject['loader'] = async () => {
    const companyInfo = await PublicClient.getCompanyInfo();

    return defer({ companyInfo });
};
