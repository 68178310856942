import React, { Suspense } from 'react';

import { Await, useLoaderData } from 'react-router-dom';

import { MainSpinner } from '@ninox/ninox-components/lib/Spinner';
import { LocaleProvider } from '@ninox/ninox-components/locale';

import { SignIn } from '../shared/SignIn';
import { ProtectedOutlet } from './ProtectedOutlet';
import { SessionContext } from './session.context';
import type { LoaderData } from './session.types';
import { useSession } from './useSession';

export const SessionLayout = () => {
    const { session } = useLoaderData() as LoaderData;
    const store = useSession(session);

    return (
        <Suspense fallback={<MainSpinner />}>
            <Await errorElement={process.env.MODE === 'production' && <SignIn />} resolve={session}>
                <SessionContext.Provider value={store}>
                    <LocaleProvider language={store?.user?.language}>
                        <ProtectedOutlet />
                    </LocaleProvider>
                </SessionContext.Provider>
            </Await>
        </Suspense>
    );
};
