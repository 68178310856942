export type UserPlanInfo = {
    basic: number;
    collaborators: number;
    contributors: number;
    free: number;
    latestPayment: PaymentInPlanInfo | null;
    members: number;
    /**
     * @deprecated
     */
    name?: Tier | null;
    provider: PaymentProvider;
    teamCount: number;
    tier: Tier | null;
    total: number;
};

export type PaymentInPlanInfo = {
    provider: PaymentProvider;
};

export enum PaymentProvider {
    none = 'none',
    avangate = 'avangate',
    apple = 'apple',
    custom = 'custom',
    cleverbridge = 'cleverbridge'
}

export enum Tier {
    basic = 'basic',
    free = 'free',
    trial = 'trial',
    starter = 'starter',
    pro = 'pro',
    enterprise = 'enterprise'
}
