import { createContext } from 'react';

import type { SessionContextType, SessionUser } from './session.types';

export const initialUser: SessionUser = {
    id: '',
    email: '',
    language: undefined,
    isRoot: false
};

export const initialSessionState: SessionContextType = {
    user: initialUser,
    setUser: () => console.warn('setUser', 'not implemented')
};

export const SessionContext = createContext<SessionContextType>(initialSessionState);
