import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@ninox/ninox-components/lib/Button';
import { Snackbar } from '@ninox/ninox-components/lib/Snackbar';
import { LocaleContext, LocaleFormatContext } from '@ninox/ninox-components/locale';
import { startViewTransition } from '@ninox/ninox-components/utils/startViewTransition';

import { ProgressNav } from '../../../../shared/ProgressNav';
import { OrderContext } from '../OrderCloud/OrderCloud.context';
import type { LoaderData } from './CreateCloud.loader';

export const Layout = () => {
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const locale = useContext(LocaleContext);
    const { actions } = useContext(OrderContext);
    const { country } = useContext(LocaleFormatContext);
    const navigate = useNavigate();
    const { companyInfo } = useLoaderData() as LoaderData;
    const params = useParams();
    const isConfirmationPage = !!params.domain;

    useEffect(() => {
        actions.updateOrder({
            city: companyInfo?.address.city || '',
            companyId: companyInfo?.id || '',
            companyName: companyInfo?.name || '',
            firstName: companyInfo?.billingFirstName || '',
            lastName: companyInfo?.billingLastName || '',
            invoicingEmail: companyInfo?.billingEmail || '',
            country: country(companyInfo?.address.country) || '',
            countryCode: companyInfo?.address.country || '',
            region: companyInfo?.address.region || '',
            street: companyInfo?.address.street || '',
            zip: companyInfo?.address.postCode || ''
        });
    }, [JSON.stringify(companyInfo)]);

    const backToOverview = () => {
        startViewTransition(() => navigate('..'));
    };

    const routes = useMemo(() => {
        if (isConfirmationPage) {
            return [
                { value: 'domain', label: locale.domainInfo },
                { value: 'confirmation', label: locale.billingInfo },
                { value: 'summary', label: locale.orderSummary }
            ];
        }

        return [
            { value: 'domain', label: locale.domainInfo },
            { value: 'billing', label: locale.billingInfo },
            { value: 'summary', label: locale.orderSummary }
        ];
    }, [locale, isConfirmationPage]);

    return (
        <>
            <Button
                emphasis="tertiary"
                icon="chevron_left"
                onClick={isConfirmationPage ? backToOverview : () => setShowConfirmCancel(true)}
            >
                {locale.back}
            </Button>

            {isConfirmationPage ? (
                <Outlet />
            ) : (
                <ProgressNav backwardsOnly routes={routes} style={{ marginLeft: 0, maxWidth: '50rem' }}>
                    <Outlet />
                </ProgressNav>
            )}

            <Snackbar
                ariaLabelCancel={locale.close}
                ariaLabelConfirm={locale.confirmRemove}
                emphasis="attention"
                isOpened={showConfirmCancel}
                onClose={() => setShowConfirmCancel(false)}
                onConfirm={() => backToOverview()}
            >
                {locale.confirmLeaveOrder}
            </Snackbar>
        </>
    );
};
