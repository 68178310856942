import * as mixpanel from 'mixpanel-browser';

export interface GlobalOptions {
    device: 'web';
}

export function init(): void {
    if (process.env.MODE === 'production' && process.env.MIXPANEL_ID) {
        try {
            mixpanel?.init?.(process.env.MIXPANEL_ID);
        } catch (err) {
            console.warn(err);
        }
    }
}

/**
 * register options that will be attached to any event sent from here on
 */
export function register(options: any): void {
    if (!shouldTrack()) {
        return;
    }

    try {
        mixpanel?.register?.(options);
    } catch (err) {
        console.warn(err);
    }
}

/**
 * track a single event
 */
export function track(category: string, action: string, options?: any): void {
    if (!shouldTrack()) {
        return;
    }

    const finalOptions = { device: 'web', ...(options || {}) };

    try {
        mixpanel?.track?.(`Admin ${category} ${action}`, finalOptions);
    } catch (err) {
        console.warn(err);
    }
}

function getCookie(cookieName: string): string | null {
    try {
        const name = `${cookieName}=`;
        const decodedCookie = decodeURIComponent(document.cookie); // Decoding here
        const cookieArray = decodedCookie.split(';').map((cookie) => cookie.trim());
        const foundCookie = cookieArray.find((cookie) => cookie.startsWith(name));

        return foundCookie ? foundCookie.substring(name.length) : null;
    } catch (error) {
        console.error('Failed to decode cookie:', error);

        return null;
    }
}

function parseCookieString(cookieString: string): any {
    // Replace encoded single quotes with actual double quotes
    let jsonString = cookieString.replace(/%27/g, '"');

    // Add missing braces and commas if needed (fix for malformed JSON)
    jsonString = jsonString.replace(/([a-zA-Z0-9]+):/g, '"$1":').replace(/'/g, '"');

    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error('Failed to parse cookie string:', jsonString, error);

        return null;
    }
}

function hasTrackingConsent() {
    const cookieValue = getCookie('CookieConsent');

    if (cookieValue) {
        const cookieObject = parseCookieString(cookieValue);

        if (cookieObject) {
            return cookieObject.statistics === true;
        }
    }

    return false;
}

function shouldTrack() {
    return hasTrackingConsent() && process.env.MODE === 'production';
}
