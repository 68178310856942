import React, { useContext } from 'react';

import { Outlet } from 'react-router-dom';

import { SignIn } from '../shared/SignIn';
import { SessionContext } from './session.context';

export const ProtectedOutlet = () => {
    const context = useContext(SessionContext);

    if (context.user.id) {
        return <Outlet context={context} />;
    }

    return <SignIn />;
};
