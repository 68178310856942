import styled, { keyframes } from 'styled-components';

import { ServerConnection } from '../../../../shared/Icons';

const hueRotate = keyframes`
    0% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(180deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
`;

export const MaintenanceIcon = styled(ServerConnection)`
    margin: auto;
    transform-origin: 50% 50%;
    animation-name: ${hueRotate};
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
`;
