import React from 'react';

import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { ErrorElement } from '../../../../shared/ErrorElement';

export const DeleteAccount = {
    element: <Outlet />,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "DeleteAccountPage" */ './DeleteAccountPage')
        },
        {
            path: 'confirm',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "DeleteAccountConfirm" */ './DeleteAccountConfirm')
        },
        {
            path: 'success',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "DeleteAccountSuccess" */ './DeleteAccountSuccess')
        }
    ]
} satisfies RouteObject;
