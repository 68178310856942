import styled from 'styled-components';

import { BorderRadius, BorderWidth, FontSize, FontWeight, MediaQuery, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.medium};
    width: 100%;
    box-sizing: border-box;

    @media ${MediaQuery.S} {
        max-width: 32rem;
    }
`;

export const CurrentVersion = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;

    width: fit-content;
    gap: ${Spacing.xxx_small};
    padding: ${Spacing.xx_small} ${Spacing.small};

    ${({ theme }) => theme.tag.primary}

    border-style: solid;
    border-width: ${BorderWidth.tiny};
    border-radius: ${BorderRadius.large};

    font-size: ${FontSize.small};
`;

export const Head = styled.div`
    font-weight: ${FontWeight.medium};
`;

export const Version = styled.div`
    font-weight: ${FontWeight.semibold};
`;
