export enum methods {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT'
}

export type ApiMock = {
    url: string;
    ignoreQueryParams?: boolean;
    status: number;
    method: `${methods}`;
    response: Response | ((request: Request) => Response);
    delay?: number;
};

type Data = Record<string, any> | Array<Record<string, any>>;

type Response = Data;

type Request = {
    url: string;
    body: string;
    searchParams: Record<string, string>;
};
