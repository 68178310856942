import React from 'react';

import { OrderContext } from './OrderCloud.context';
import { useOrderCloud } from './OrderCloud.hook';

type Props = {
    children: React.ReactNode;
};

export const OrderContextProvider = ({ children }: Props) => {
    const store = useOrderCloud();

    return <OrderContext.Provider value={store}>{children}</OrderContext.Provider>;
};
