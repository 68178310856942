import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { loader as loaderUsage } from '../../../shared/Usage/Usage.public.loader';
import { loader as loaderPayments } from './loader/payments.loader';
import { loader as loaderPlanInfo } from './loader/planInfo.loader';
import { Layout } from './Subscriptions.layout';

export const Subscriptions = {
    element: <Layout />,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "SubscriptionsPublic" */ './SubscriptionsPublic')
        },
        {
            path: 'usage',
            loader: loaderUsage,
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Usage" */ '../../../shared/Usage')
        },
        {
            path: 'collaborators',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Collaborators" */ './Collaborators')
        },
        {
            path: 'planInfo/:userId',
            loader: loaderPlanInfo
        },
        {
            path: 'payments/:userId',
            loader: loaderPayments
        }
    ]
} satisfies RouteObject;
