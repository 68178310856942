import styled from 'styled-components';

import { BorderWidth, FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Footer = styled.footer`
    display: flex;
    justify-self: flex-end;

    padding: ${Spacing.small} ${Spacing.large};
    contain: layout;

    ${({ theme }) => theme.nav.primary}

    border-style: solid;
    border-width: 0;
    border-top-width: ${BorderWidth.tiny};
`;

export const Message = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: ${Spacing.x_small};
    margin: auto;

    font-size: ${FontSize.large};
    font-weight: ${FontWeight.semibold};
`;
