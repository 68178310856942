import styled from 'styled-components';

import { FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Subheader = styled.header`
    margin: ${Spacing.small} 0;
    color: ${({ theme }) => theme.text.header};
    font-size: ${FontSize.large};
    font-weight: ${FontWeight.semibold};
    text-wrap: balance;
`;
