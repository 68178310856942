import styled from 'styled-components';

import { Scrollbars } from '@ninox/ninox-components/lib/Scrollbars';

export const Scroll = styled(Scrollbars)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    overflow: auto;
    scrollbar-gutter: auto;

    box-sizing: border-box;
    width: 100%;
`;

Scroll.defaultProps = {
    showBackground: true,
    autoHide: false
};
