import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Layout } from './Security.layout';

export const Security = {
    element: <Layout />,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ChangePassword" */ './ChangePassword')
        }
    ]
} satisfies RouteObject;
