import '@ninox/ninox-components/main.css';
import '@ninox/ninox-components/fonts.css';

import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';

const container = document.getElementById('root');

if (container) {
    createRoot(container).render(<App />);
}
