import React from 'react';

import { SvgIcon, type SvgIconProps } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const EmailError = ({ color, ...props }: SvgIconProps) => {
    return (
        <SvgIcon intrinsicSize={58} stroke="none" {...props}>
            <path
                d="M10.2191 21.1051C10.0758 21.1051 9.93093 21.0758 9.79256 21.0123L0.734883 16.8677C0.220465 16.6316 -0.00744258 16.0228 0.228604 15.5084C0.46465 14.994 1.07349 14.7677 1.58791 15.0021L10.6456 19.1467C11.16 19.3828 11.3879 19.9916 11.1519 20.506C10.9793 20.8837 10.6065 21.1051 10.2191 21.1051ZM14.8228 16.2344C14.5607 16.2344 14.2986 16.1351 14.0984 15.9333L7.36372 9.20023C6.96325 8.79977 6.96325 8.15023 7.36372 7.74977C7.76418 7.3493 8.41372 7.3493 8.81419 7.74977L15.5488 14.4844C15.9493 14.8849 15.9493 15.5344 15.5488 15.9349C15.3486 16.1335 15.0865 16.2344 14.8228 16.2344ZM19.6007 11.6877C19.2067 11.6877 18.8307 11.4581 18.6614 11.074L14.6323 1.90233C14.4044 1.38302 14.6405 0.77907 15.1581 0.551163C15.6774 0.323256 16.2814 0.559303 16.5093 1.07698L20.5384 10.2486C20.7663 10.7679 20.5302 11.3719 20.0126 11.5998C19.8791 11.66 19.7391 11.6877 19.6007 11.6877Z"
                fill="currentColor"
            />
            <path
                d="M43.4284 59.5367C43.0344 59.5367 42.6584 59.3072 42.4891 58.923L38.46 49.7514C38.2321 49.2321 38.4681 48.6281 38.9858 48.4002C39.5035 48.1723 40.1091 48.4084 40.337 48.926L44.366 58.0977C44.594 58.617 44.3579 59.2209 43.8402 59.4488C43.7067 59.5091 43.5667 59.5367 43.4284 59.5367ZM50.9102 52.5514C50.6481 52.5514 50.386 52.4521 50.1858 52.2502L43.4512 45.5156C43.0507 45.1151 43.0507 44.4656 43.4512 44.0651C43.8516 43.6646 44.5012 43.6646 44.9016 44.0651L51.6363 50.7998C52.0367 51.2002 52.0367 51.8498 51.6363 52.2502C51.436 52.4505 51.1723 52.5514 50.9102 52.5514ZM57.837 45.0907C57.6937 45.0907 57.5488 45.0614 57.4105 44.9979L48.3528 40.8533C47.8384 40.6172 47.6105 40.0084 47.8465 39.4939C48.0826 38.9795 48.6914 38.7532 49.2058 38.9877L58.2635 43.1323C58.7779 43.3684 59.0058 43.9772 58.7698 44.4916C58.5988 44.8677 58.226 45.0907 57.837 45.0907Z"
                fill="currentColor"
            />
            <path
                d="M16.8805 57.4188C16.7958 57.4188 16.7112 57.4188 16.6265 57.4172C12.814 57.3521 9.20488 55.8072 6.46512 53.0691L6.41953 53.0235C0.749534 47.3584 0.588371 38.286 6.06302 32.7984L15.2656 23.573C15.666 23.1726 16.3156 23.1709 16.716 23.5714C17.1165 23.9719 17.1181 24.6214 16.7177 25.0219L7.51512 34.2472C2.83977 38.934 2.9993 46.7056 7.87 51.573L7.91558 51.6186C10.2777 53.9791 13.3853 55.3107 16.664 55.366C19.9279 55.4214 22.9688 54.207 25.2267 51.9442L34.4293 42.7188C34.8298 42.3184 35.4793 42.3167 35.8798 42.7172C36.2802 43.1177 36.2819 43.7672 35.8814 44.1677L26.6788 53.393C24.0807 55.9944 20.61 57.4188 16.8805 57.4188ZM43.1549 36.58C42.8928 36.58 42.6307 36.4807 42.4305 36.2805C42.03 35.88 42.0284 35.2305 42.4288 34.83L51.6314 25.6047C56.3067 20.9179 56.1472 13.1463 51.2765 8.27884L51.2293 8.23163C48.8672 5.87116 45.7612 4.54116 42.4826 4.48418C39.2202 4.43209 36.1777 5.64325 33.9198 7.90605L24.7172 17.1314C24.3167 17.5319 23.6672 17.5335 23.2667 17.133C22.8663 16.7326 22.8646 16.083 23.2651 15.6826L32.4677 6.45721C35.1228 3.79558 38.6895 2.36628 42.5184 2.43302C46.3309 2.49814 49.94 4.04302 52.6798 6.78116L52.727 6.82837C58.397 12.4935 58.5565 21.5658 53.0835 27.0535L43.8809 36.2788C43.6807 36.4791 43.4186 36.58 43.1549 36.58Z"
                fill={color}
            />
        </SvgIcon>
    );
};

EmailError.defaultProps = {
    size: Size.xxl,
    color: Color.accent['700']
};
