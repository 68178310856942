import React from 'react';

import type { NavLinkProps } from 'react-router-dom';

import { useId } from '@ninox/ninox-components/hooks/useId';
import { Size } from '@ninox/ninox-components/theme';

import { Checkmark, Label, PageLink } from './ProgressTab.styles';

type Props = Pick<NavLinkProps, 'to'> & {
    children: React.ReactNode;
    controls: string;
    id?: string;
    index: number;
    isChecked: boolean;
    isCurrent: boolean;
    isDisabled?: boolean;
    onSelect: () => void;
};

export const ProgressTab = ({
    children,
    controls,
    id,
    isDisabled,
    index,
    isChecked,
    isCurrent,
    onSelect,
    to
}: Props) => {
    const label = useId();
    const column = 3 * (index + 1) - 1;

    return (
        <>
            <PageLink
                aria-checked={isChecked}
                aria-controls={controls}
                aria-disabled={isDisabled}
                aria-hidden={!isCurrent}
                aria-labelledby={label.id}
                aria-selected={isCurrent}
                data-path={to}
                id={id}
                role="tab"
                style={{ gridColumn: column }}
                tabIndex={isDisabled || !isCurrent ? -1 : 0}
                to={to}
                onClick={preventDefault}
                onFocus={onSelect}
            >
                {isChecked && <Checkmark icon="check" size={Size.s} weight={400} />}
            </PageLink>
            <Label
                id={label.id}
                style={{ gridColumnStart: column - 1, gridColumnEnd: 'span 3' }}
                onClick={isDisabled ? undefined : onSelect}
            >
                {children}
            </Label>
        </>
    );
};

function preventDefault(e: React.MouseEvent | React.FocusEvent) {
    e.preventDefault();
}
