import React from 'react';

import { SvgIcon, type SvgIconProps } from '@ninox/ninox-components/lib/Icon/shared';
import { Color } from '@ninox/ninox-components/theme';

export const ContactSupport = (props: SvgIconProps) => {
    return (
        <SvgIcon height="300" viewBox="0 0 300 200" width="200" {...props}>
            <defs>
                <clipPath id="clip0_624_6218">
                    <rect fill="white" height="89.25" transform="translate(92.5 50)" width="102" />
                </clipPath>
                <clipPath id="clip1_624_6218">
                    <rect fill="white" height="140" transform="translate(18.25 23.25)" width="128.25" />
                </clipPath>
                <clipPath id="clip2_624_6218">
                    <rect fill="white" height="136.75" transform="translate(154 27.25)" width="113.5" />
                </clipPath>
                <clipPath id="clip3_624_6218">
                    <rect fill="white" height="8.5" transform="translate(158.5 45.75)" width="8.5" />
                </clipPath>
                <clipPath id="clip4_624_6218">
                    <rect fill="white" height="8.5" transform="translate(174.25 45.5)" width="8.5" />
                </clipPath>
                <clipPath id="clip5_624_6218">
                    <rect fill="white" height="8.5" transform="translate(189.5 45.5)" width="8.5" />
                </clipPath>
                <clipPath id="clip6_624_6218">
                    <rect fill="white" height="1.75" transform="translate(87 154.5)" width="39" />
                </clipPath>
                <clipPath id="clip7_624_6218">
                    <rect fill="white" height="1.5" transform="translate(87 162)" width="47.75" />
                </clipPath>
                <clipPath id="clip8_624_6218">
                    <rect fill="white" height="1.5" transform="translate(97.5 162)" width="47.75" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip0_624_6218)">
                <path d="M178.213 50H92.5V120.306H178.213V50Z" fill="#FFCD36" />
                <path
                    d="M193.751 139.25H108.038C107.839 139.25 107.649 139.171 107.508 139.031C107.368 138.89 107.289 138.699 107.289 138.5V68.1943C107.289 67.9954 107.368 67.8046 107.508 67.664C107.649 67.5234 107.839 67.4444 108.038 67.4444H193.751C193.95 67.4444 194.14 67.5234 194.28 67.664C194.421 67.8046 194.5 67.9954 194.5 68.1943V138.5C194.5 138.699 194.421 138.89 194.28 139.031C194.14 139.171 193.95 139.25 193.751 139.25ZM108.786 137.75H193.002V68.9442H108.786V137.75Z"
                    fill="#262103"
                />
                <path
                    d="M193.277 67.6129L143.355 108.563L144.304 109.723L194.225 68.7735L193.277 67.6129Z"
                    fill="#262103"
                />
                <path
                    d="M108.512 67.6142L107.563 68.7748L144.796 99.3152L145.745 98.1546L108.512 67.6142Z"
                    fill="#262103"
                />
            </g>
            <g clipPath="url(#clip1_624_6218)">
                <path
                    d="M73.745 31.4987C43.096 31.4987 18.25 56.3835 18.25 87.0772C18.25 117.771 43.096 142.655 73.745 142.655V31.4987Z"
                    fill="#E1E3E3"
                />
                <path
                    d="M73.8543 87.4952H66.416V86.0045H73.1056L72.7754 23.9974L74.2651 23.9894L74.5993 86.7451C74.6004 86.9428 74.523 87.1328 74.384 87.2734C74.2451 87.414 74.056 87.4937 73.8583 87.495H73.8543V87.4952Z"
                    fill="#261C03"
                />
                <path
                    d="M51.3063 80.5399C48.1467 80.5451 45.0566 79.6131 42.427 77.8618C39.7974 76.1104 37.7465 73.6185 36.5337 70.7013C35.321 67.7841 35.001 64.5728 35.6142 61.4737C36.2274 58.3746 37.7462 55.527 39.9785 53.2913C42.2108 51.0555 45.0562 49.5322 48.1547 48.9139C51.2532 48.2957 54.4654 48.6103 57.385 49.8181C60.3045 51.0258 62.8002 53.0724 64.5561 55.6988C66.312 58.3252 67.2492 61.4134 67.2492 64.5725C67.248 68.8024 65.5687 72.859 62.5799 75.8525C59.591 78.8459 55.5367 80.5317 51.3063 80.5399ZM51.3063 50.0969C48.4423 50.0924 45.6414 50.9375 43.2579 52.5251C40.8744 54.1128 39.0155 56.3716 37.9163 59.016C36.8171 61.6603 36.5271 64.5712 37.083 67.3803C37.6389 70.1895 39.0156 72.7707 41.0391 74.7972C43.0625 76.8238 45.6417 78.2047 48.4503 78.7652C51.2588 79.3257 54.1706 79.0406 56.817 77.946C59.4635 76.8514 61.7258 74.9964 63.3176 72.6159C64.9094 70.2353 65.7592 67.4362 65.7595 64.5725C65.7582 60.7381 64.2357 57.0608 61.5261 54.3473C58.8166 51.6338 55.1412 50.1058 51.3063 50.0984V50.0969Z"
                    fill="#261C03"
                />
                <path
                    d="M62.7422 64.097H47.7373C47.7487 66.0789 48.5441 67.9757 49.9498 69.3731C51.3554 70.7705 53.2571 71.5549 55.2392 71.5549C57.2214 71.5549 59.123 70.7705 60.5287 69.3731C61.9343 67.9757 62.7297 66.0789 62.7412 64.097H62.7422Z"
                    fill="#261C03"
                />
                <path
                    d="M21.1502 71.7897L19.7051 71.4302C20.4033 68.7116 21.337 66.0589 22.4956 63.5023C22.5556 63.3741 22.651 63.2656 22.7704 63.1896C22.8898 63.1136 23.0285 63.0732 23.1701 63.0731L62.7421 63.0952V64.5872L23.6519 64.5653C22.6262 66.9027 21.7896 69.3185 21.1502 71.7897Z"
                    fill="#261C03"
                />
                <path
                    d="M57.4292 65.5762C58.9088 65.5762 60.1083 64.3749 60.1083 62.8929C60.1083 61.411 58.9088 60.2096 57.4292 60.2096C55.9495 60.2096 54.75 61.411 54.75 62.8929C54.75 64.3749 55.9495 65.5762 57.4292 65.5762Z"
                    fill="#E1E3E3"
                />
                <path
                    d="M18.25 87.0772C46.7386 87.0772 69.8333 108.199 69.8333 136.73V163.25H18.25V87.0772Z"
                    fill={Color.accent[800]}
                />
                <path
                    d="M136.246 50.0977H134.751V33.623C134.749 31.4339 133.878 29.3351 132.33 27.7872C130.782 26.2392 128.683 25.3685 126.493 25.3661L72.4326 24.7447L72.4501 23.25L126.502 23.8713C129.086 23.8761 131.563 24.9053 133.39 26.7333C135.217 28.5613 136.244 31.039 136.246 33.623V50.0977Z"
                    fill="#262103"
                />
                <path d="M145.752 59.0001L135.514 38.4924L125.275 59.0001H145.752Z" fill="#E1E3E3" />
                <path
                    d="M145.753 59.7467H125.276C125.148 59.7467 125.023 59.7142 124.912 59.6522C124.801 59.5902 124.707 59.5008 124.64 59.3925C124.573 59.2842 124.535 59.1605 124.529 59.0333C124.523 58.9061 124.55 58.7795 124.607 58.6655L134.846 38.1581C134.908 38.0339 135.003 37.9294 135.121 37.8563C135.239 37.7833 135.375 37.7446 135.514 37.7446C135.653 37.7446 135.789 37.7833 135.907 37.8563C136.026 37.9294 136.121 38.0339 136.183 38.1581L146.422 58.6655C146.478 58.7795 146.505 58.9061 146.499 59.0333C146.494 59.1605 146.455 59.2842 146.388 59.3925C146.322 59.5008 146.228 59.5902 146.117 59.6522C146.005 59.7142 145.88 59.7467 145.753 59.7467ZM126.484 58.252H144.544L135.514 40.1656L126.484 58.252Z"
                    fill="#262103"
                />
                <path
                    d="M70.578 161.661V138.738C70.6074 124.864 65.1432 111.543 55.3796 101.685C45.6266 91.7842 32.7962 86.3318 19.2521 86.3318H18.25V87.8231H19.2524C32.3948 87.8231 44.849 93.1177 54.3199 102.732C63.8072 112.311 69.1167 125.255 69.0883 138.737V162.405C69.0881 162.503 69.1072 162.601 69.1445 162.691C69.1818 162.782 69.2367 162.864 69.3059 162.934C69.375 163.003 69.4572 163.058 69.5477 163.096C69.6382 163.133 69.7353 163.153 69.8333 163.153H70.5945L70.578 161.661Z"
                    fill="#261C03"
                />
                <path d="M80.8697 92.6068H66.416V94.0988H80.8697V92.6068Z" fill="#261C03" />
            </g>
            <g clipPath="url(#clip2_624_6218)">
                <path
                    d="M230.801 60.9322C230.805 59.4817 229.632 58.3029 228.181 58.2992C226.73 58.2955 225.55 59.4683 225.547 60.9188C225.543 62.3692 226.716 63.548 228.167 63.5517C229.618 63.5554 230.798 62.3826 230.801 60.9322Z"
                    fill="#E1E3E3"
                />
                <path
                    d="M213.219 34.6008C243.273 34.6773 267.575 59.0941 267.498 89.1349C267.422 119.176 242.996 143.469 212.942 143.392L213.219 34.6008Z"
                    fill="#E1E3E3"
                />
                <path
                    d="M267.497 87.3293C240.144 87.2598 216.919 108.414 216.846 136.989L216.778 163.487L267.5 163.612L267.497 87.3293Z"
                    fill="#FF7C00"
                />
                <path
                    d="M213.299 89.4264L220.595 89.4449L220.598 87.9435L214.059 87.927L214.537 27.2617L213.036 27.25L212.551 88.6689C212.55 88.7676 212.569 88.8654 212.605 88.9569C212.642 89.0484 212.697 89.1316 212.766 89.2019C212.836 89.2722 212.918 89.3281 213.009 89.3665C213.1 89.4049 213.197 89.425 213.296 89.4256L213.299 89.4264Z"
                    fill="#261C03"
                />
                <path
                    d="M206.736 94.4572L206.732 95.9586L220.905 95.9947L220.909 94.4934L206.736 94.4572Z"
                    fill="#261C03"
                />
                <path
                    d="M223.93 66.5324L238.643 66.5699C238.623 68.5111 237.836 70.3657 236.455 71.7295C235.073 73.0934 233.209 73.8559 231.268 73.8509C229.327 73.846 227.467 73.074 226.092 71.7031C224.718 70.3323 223.941 68.4737 223.93 66.5324H223.93Z"
                    fill="#261C03"
                />
                <path
                    d="M231.768 65.3743C231.772 63.9239 230.599 62.7451 229.148 62.7414C227.697 62.7377 226.517 63.9105 226.514 65.3609C226.51 66.8114 227.683 67.9901 229.134 67.9938C230.585 67.9975 231.765 66.8247 231.768 65.3743Z"
                    fill="#E1E3E3"
                />
                <path
                    d="M266.518 86.6319C253.23 86.5981 240.63 91.9049 231.038 101.574C221.44 111.193 216.046 124.225 216.037 137.814L215.98 160.229H215.964L215.96 163.517L217.46 163.489C217.46 163.489 217.48 161.399 217.481 160.985L217.54 137.818C217.549 124.623 222.787 111.97 232.106 102.63C241.413 93.2495 253.633 88.1005 266.515 88.1335L267.498 88.136L267.501 86.6344L266.518 86.6319Z"
                    fill="#261C03"
                />
                <path
                    d="M173.994 164C172.706 164 171.431 163.745 170.242 163.251C169.054 162.756 167.974 162.032 167.065 161.12C166.157 160.208 165.437 159.125 164.948 157.934C164.459 156.743 164.209 155.467 164.214 154.179L164.233 149.063L165.732 149.068L165.714 154.184C165.709 155.283 165.924 156.372 166.344 157.387C166.765 158.402 167.383 159.323 168.163 160.097C168.943 160.871 169.87 161.482 170.888 161.895C171.906 162.307 172.997 162.513 174.095 162.499L216.866 161.991L216.884 163.491L174.114 163.999L173.994 164Z"
                    fill="#262103"
                />
                <path d="M154.747 129.459L175.288 129.511L164.965 150.063L154.747 129.459Z" fill="#E1E3E3" />
                <path
                    d="M164.964 150.811C164.825 150.811 164.689 150.772 164.571 150.698C164.453 150.625 164.358 150.52 164.296 150.396L154.078 129.791C154.021 129.677 153.995 129.55 154.001 129.423C154.007 129.296 154.045 129.172 154.113 129.064C154.18 128.955 154.274 128.866 154.385 128.804C154.497 128.742 154.622 128.71 154.75 128.71L175.291 128.762C175.419 128.763 175.544 128.796 175.655 128.858C175.767 128.921 175.86 129.01 175.927 129.119C175.994 129.228 176.031 129.352 176.037 129.479C176.042 129.606 176.015 129.733 175.958 129.847L165.635 150.399C165.573 150.523 165.477 150.628 165.358 150.701C165.24 150.773 165.103 150.812 164.964 150.811ZM155.954 130.211L164.97 148.388L174.078 130.256L155.954 130.211Z"
                    fill="#262103"
                />
            </g>
            <g clipPath="url(#clip3_624_6218)">
                <path
                    d="M162.75 54.2501C165.097 54.2501 167 52.3473 167 50.0001C167 47.6528 165.097 45.75 162.75 45.75C160.403 45.75 158.5 47.6528 158.5 50.0001C158.5 52.3473 160.403 54.2501 162.75 54.2501Z"
                    fill="#261C03"
                />
            </g>
            <g clipPath="url(#clip4_624_6218)">
                <path
                    d="M178.5 54.0001C180.847 54.0001 182.75 52.0973 182.75 49.7501C182.75 47.4028 180.847 45.5 178.5 45.5C176.153 45.5 174.25 47.4028 174.25 49.7501C174.25 52.0973 176.153 54.0001 178.5 54.0001Z"
                    fill="#261C03"
                />
            </g>
            <g clipPath="url(#clip5_624_6218)">
                <path
                    d="M193.75 54.0001C196.097 54.0001 198 52.0973 198 49.7501C198 47.4028 196.097 45.5 193.75 45.5C191.403 45.5 189.5 47.4028 189.5 49.7501C189.5 52.0973 191.403 54.0001 193.75 54.0001Z"
                    fill="#261C03"
                />
            </g>
            <g clipPath="url(#clip6_624_6218)">
                <path d="M137.25 154.5H87V156.25H137.25V154.5Z" fill="#262103" />
            </g>
            <g clipPath="url(#clip7_624_6218)">
                <path d="M134.75 162H87V163.5H134.75V162Z" fill="#262103" />
            </g>
            <g clipPath="url(#clip8_624_6218)">
                <path d="M145.25 162H97.5V163.5H145.25V162Z" fill="#262103" />
            </g>
        </SvgIcon>
    );
};
