import styled, { css } from 'styled-components';

import { BorderWidth, FontSize, FontWeight, MediaQuery, Spacing } from '@ninox/ninox-components/theme';

const Padding = css`
    padding: ${Spacing.large};
    @media ${MediaQuery.S} {
        padding-left: ${Spacing.x_large};
        padding-right: ${Spacing.x_large};
    }

    @media ${MediaQuery.M} {
        padding-left: ${Spacing.xx_large};
        padding-right: ${Spacing.xx_large};
    }
`;

export const Container = styled.header`
    position: sticky;
    left: 0;

    display: flex;
    flex-flow: column;
    align-items: start;
    box-sizing: border-box;
    flex-shrink: 0;

    min-width: 100%;
    ${Padding}
    gap: ${Spacing.medium};

    ${({ theme }) => theme.nav.secondary}

    border-style: solid;
    border-width: 0;
    border-bottom-width: ${BorderWidth.tiny};

    contain: layout;
    line-height: 1.5;

    & > [hidden] {
        display: none;
    }
`;

export const Tabs = styled.nav`
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;

    margin-left: -${Spacing.x_small};
    margin-right: -${Spacing.x_small};
    margin-bottom: ${Spacing.xxx_small};

    ${({ theme }) => theme.nav.secondary}

    & > * {
        flex-shrink: 0;
    }

    &[aria-hidden='true'] {
        display: none;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.text.header};

    font-size: ${FontSize.xx_large};
    font-weight: ${FontWeight.semibold};
    text-wrap: balance;
    max-width: 80ch;
`;

export const Description = styled.div`
    font-size: ${FontSize.large};
    font-weight: ${FontWeight.medium};
    text-wrap: pretty;
    max-width: 80ch;
`;

export const TabPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 auto;
    box-sizing: border-box;
    min-width: 100%;

    ${Padding}
`;
