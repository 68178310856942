import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

import { fromFormData } from '@ninox/ninox-components/utils/formData';

import { PublicClient } from '../../../../api/public';
import { ProviderRegionNames } from '../Shared/OrderCloud.constants';

export const action = async function ({ request }) {
    const formData = await request.formData();
    const { hostingProvider } = fromFormData(formData);

    if (!hostingProvider) return;

    const data = await PublicClient.getProviderRegions(hostingProvider);

    if (data) {
        const locs = data.map((item: string) => ({
            label: ProviderRegionNames[item as keyof typeof ProviderRegionNames],
            value: item
        }));

        return json([{ label: '', options: locs }]);
    }
} satisfies ActionFunction;
