import styled, { css } from 'styled-components';

import { SimpleButton } from '@ninox/ninox-components/lib/Button';
import { NavButton } from '@ninox/ninox-components/lib/NavButton';
import { Scrollbars } from '@ninox/ninox-components/lib/Scrollbars';
import { BorderWidth, MediaQuery, Spacing } from '@ninox/ninox-components/theme';
import { alpha } from '@ninox/ninox-components/theme/utils';

export const Sidebar = styled(Scrollbars)<{ isRelative?: boolean }>`
    position: relative;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    min-width: 16rem;
    max-width: 30vw;
    min-height: 100%;
    padding: ${Spacing.large} ${Spacing.medium};
    gap: ${Spacing.small};

    border-style: solid;
    border-width: 0;
    border-right-width: ${BorderWidth.tiny};

    ${({ theme }) => theme.nav.side};

    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    ${({ isRelative }) =>
        !isRelative &&
        css`
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;

            transform: translateX(0);

            &[aria-expanded='false'] {
                transform: translateX(-100%);
            }
        `};
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    gap: ${Spacing.small};
`;

export const Top = styled(Section)`
    flex: 1 0 auto;
`;

export const Bottom = styled(Section)`
    flex: 0 0 auto;
`;

export const Opener = styled(SimpleButton)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${Spacing.x_small};
    z-index: 1;
    grid-column: 1;

    background-color: ${({ theme }) => theme.grid.primary};
    opacity: 0.001;

    cursor: pointer;

    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease;

    &:hover {
        opacity: 1;
    }

    @media ${MediaQuery.S} {
        display: none;
    }
`;

export const LinkButton = styled(NavButton)`
    width: 100%;
    min-width: max-content;
`;

export const Backdrop = styled.div`
    position: absolute;
    inset: 0;
    z-index: 1;
    contain: layout size;
    grid-column: 1 / -1;

    background-color: ${({ theme }) => theme.backdrop.primary};

    will-change: background-color, backdrop-filter;

    cursor: pointer;
    pointer-events: all;
    visibility: visible;

    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease;
    transition-behavior: allow-discrete;

    backdrop-filter: blur(${Spacing.tiny});

    &[aria-hidden='true'] {
        background-color: ${({ theme }) => alpha(theme.backdrop.primary, 0)};
        visibility: hidden;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        backdrop-filter: blur(0px);
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none !important;
    }
`;

export const Separator = styled.div`
    border-bottom: ${BorderWidth.tiny} solid ${({ theme }) => theme.grid.primary};
    margin-top: auto;
`;

export const TabPanel = styled.div`
    display: contents;
`;
