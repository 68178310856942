import styled, { css } from 'styled-components';

import { FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div<{ centered?: boolean }>`
    display: flex;
    flex-flow: column;
    align-self: flex-start;
    align-items: flex-start;
    gap: ${Spacing.large};
    padding: ${Spacing.large};
    min-width: min-content;

    background-color: ${({ theme }) => theme.surface.primary};

    ${({ centered }) =>
        centered &&
        css`
            margin: auto;
            align-items: center;
            align-self: unset;
        `}
`;

export const Header = styled.header`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: ${Spacing.x_small};

    color: ${({ theme }) => theme.text.header};

    font-size: ${FontSize.large};
    font-weight: ${FontWeight.semibold};
    text-wrap: balance;
`;

export const Text = styled.div`
    font-size: ${FontSize.medium};
    font-weight: ${FontWeight.semibold};
    color: ${({ theme }) => theme.text.secondary};
    text-wrap: pretty;

    &:empty {
        display: none;
    }
`;
