import React from 'react';
import { Outlet } from 'react-router-dom';

import { loader } from './loader';
import { MaintenanceInfo } from './MaintenanceInfo';

import type { RouteObject } from 'react-router-dom';

export const ServerRestart = {
    element: <Outlet />,
    errorElement: <MaintenanceInfo />,
    children: [
        {
            path: '*',
            index: true,
            element: <MaintenanceInfo />
        },
        {
            path: 'check',
            loader
        }
    ]
} satisfies RouteObject;
