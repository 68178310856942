import React from 'react';

import { ErrorElement } from '../../../shared/ErrorElement';
import { action } from './Customisation.action';
import { Layout } from './Customisation.layout';
import { loader } from './Customisation.loader';

import type { RouteObject } from 'react-router-dom';

const Component = React.lazy(() => import(/* webpackChunkName: "Customisation" */ './Customisation'));

export const Customisation = {
    element: <Layout />,
    errorElement: <ErrorElement />,
    children: [
        {
            path: '*',
            index: true,
            action,
            loader,
            element: <Component />
        }
    ]
} satisfies RouteObject;
