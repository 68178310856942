import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { SignIn } from '../shared/SignIn';
import { SessionLayout } from './session.layout';
import { sessionPrivateCloud } from './session.private.loader';
import { sessionPublicCloud } from './session.public.loader';

export const SessionRoute = (isPublic: boolean, children: RouteObject[]) =>
    ({
        element: <SessionLayout />,
        id: 'root',
        loader: isPublic ? sessionPublicCloud : sessionPrivateCloud,
        shouldRevalidate: () => false,
        errorElement: <SignIn />,
        children
    }) satisfies RouteObject;
