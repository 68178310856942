import React from 'react';

import type { PropsFromStyledComponent } from '@ninox/ninox-components/utils/types';

import { Container } from './MenuButton.styles';

type Props = PropsFromStyledComponent<typeof Container> & {
    onClick?: () => void;
    isOpened: boolean;
};

export const MenuButton = ({ isOpened, ...props }: Props) => {
    return (
        <Container isOpened={isOpened} {...props}>
            <div className="line-1" id="a" />
            <div className="line-2" id="b" />
            <div className="line-3" id="c" />
            <div className="clear" />
        </Container>
    );
};
