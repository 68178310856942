import React from 'react';

import { SvgIcon, type SvgIconProps } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const InvalidUser = ({ color, ...props }: SvgIconProps) => {
    return (
        <SvgIcon intrinsicSize={58} stroke="none" {...props}>
            <path
                d="M28.5 57.3744C12.8542 57.3744 0.125582 44.6458 0.125582 29C0.125582 13.3542 12.8542 0.62558 28.5 0.62558C44.1458 0.62558 56.8744 13.3542 56.8744 29C56.8744 44.6458 44.1458 57.3744 28.5 57.3744ZM28.5 2.47C13.8716 2.47 1.97 14.3716 1.97 29C1.97 43.6284 13.8716 55.53 28.5 55.53C43.1284 55.53 55.03 43.6284 55.03 29C55.03 14.3716 43.1284 2.47 28.5 2.47Z"
                fill="currentColor"
            />
            <path
                d="M48.2823 48.9793C48.2726 48.9793 48.2628 48.9793 48.253 48.9793C47.7305 48.963 47.3202 48.5267 47.3365 48.0058L47.3626 47.1707C47.3544 40.7388 42.1191 35.5067 35.6856 35.5067H21.2509C14.8126 35.5067 9.57395 40.7454 9.57395 47.1837C9.57395 47.1935 9.57395 47.2049 9.57395 47.2147L9.54953 47.9407C9.53488 48.4502 9.11814 48.8588 8.60372 48.8588C8.08116 48.8588 7.65791 48.4372 7.65791 47.9147V47.913C7.65791 47.9033 7.65791 47.8919 7.65791 47.8821L7.68233 47.1691C7.69047 39.6953 13.774 33.6167 21.2509 33.6167H35.6856C43.1674 33.6167 49.2542 39.7035 49.2542 47.1853C49.2542 47.1951 49.2542 47.2049 49.2542 47.2147L49.2281 48.0644C49.2119 48.574 48.7919 48.9793 48.2823 48.9793Z"
                fill="currentColor"
            />
            <path
                d="M28.4674 30.483C22.9081 30.483 18.3842 25.9591 18.3842 20.3998C18.3842 14.8405 22.9081 10.3165 28.4674 10.3165C31.4547 10.3165 34.2693 11.6302 36.1919 13.9191C37.7123 15.7293 38.5507 18.0312 38.5507 20.3998C38.5507 25.9591 34.0284 30.483 28.4674 30.483ZM28.4674 12.2081C23.95 12.2081 20.2758 15.884 20.2758 20.3998C20.2758 24.9156 23.95 28.5914 28.4674 28.5914C32.9849 28.5914 36.6591 24.9172 36.6591 20.3998C36.6591 18.4756 35.9786 16.6051 34.7447 15.1351C33.1819 13.2744 30.8947 12.2081 28.4674 12.2081Z"
                fill={color}
            />
        </SvgIcon>
    );
};

InvalidUser.defaultProps = {
    size: Size.xxl,
    color: Color.accent['700']
};
