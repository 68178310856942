import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { MaterialIcon } from '@ninox/ninox-components/lib/Icon';
import { BorderWidth, FontSize, FontWeight, MediaQuery, Size, Spacing } from '@ninox/ninox-components/theme';

export const Label = styled.div`
    grid-row: 2;
    padding: ${Spacing.xxx_small};
    line-height: 1.5;
    align-self: baseline;

    text-align: center;
    white-space: normal;
    word-break: break-word;
    text-wrap: balance;

    font-size: ${FontSize.small};
    font-weight: ${FontWeight.medium};
    cursor: pointer;

    color: ${({ theme }) => theme.text.label};

    transition-property: color;
    transition-duration: 300ms;
    transition-timing-function: ease;

    @media ${MediaQuery.M} {
        font-size: ${FontSize.medium};
    }
`;

export const Checkmark = styled(MaterialIcon)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.surface.primary} !important;
`;

export const PageLink = styled(NavLink)`
    grid-row: 1;

    position: relative;
    box-sizing: content-box;

    height: ${Size.xxs};
    width: ${Size.xxs};
    margin: calc((${Size.sm} - ${Size.xxs}) / 2);
    border-radius: 50%;

    color: ${({ theme }) => theme.text.label};
    background-color: ${({ theme }) => theme.surface.primary};
    border: ${BorderWidth.medium} solid currentColor;
    outline: none;

    cursor: pointer;

    transition-property: background-color, color, margin, height, width;
    transition-duration: 400ms;
    transition-timing-function: linear;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;

        padding: 0;
        border-radius: 50%;
        background-color: currentColor;

        transform: translate(-50%, -50%);

        transition-property: padding, background-color, color;
        transition-duration: 400ms;
        transition-timing-function: linear;
    }

    &:hover:before,
    &[aria-checked='true']:before {
        padding: ${Spacing.tiny};
    }

    &:focus-visible {
        color: ${({ theme }) => theme.text.accent};
    }

    &:focus-visible + ${Label} {
        color: ${({ theme }) => theme.text.primary};
        text-decoration: underline;
    }

    &[aria-checked='true'] {
        color: ${({ theme }) => theme.text.secondary};
        background-color: currentColor;
    }

    &[aria-checked='true'] + ${Label} {
        color: ${({ theme }) => theme.text.secondary};
        font-weight: ${FontWeight.semibold};
    }

    &[aria-current] {
        color: ${({ theme }) => theme.text.accent};

        height: ${Size.sm};
        width: ${Size.sm};
        margin: 0;
    }

    &[aria-current]:before {
        padding: ${Spacing.xxx_small};
    }

    &[aria-current] + ${Label} {
        color: ${({ theme }) => theme.text.accent};
        font-weight: ${FontWeight.semibold};
    }

    &[aria-disabled='true'] {
        pointer-events: none;
        color: ${({ theme }) => theme.text.disabled};
        background-color: ${({ theme }) => theme.surface.primary};
    }

    &[aria-disabled='true'] ${Checkmark} {
        color: ${({ theme }) => theme.text.disabled} !important;
    }

    &[aria-disabled='true'] + ${Label} {
        pointer-events: none;
        color: ${({ theme }) => theme.text.disabled};
        cursor: unset;
    }
`;
