import { useContext, useMemo } from 'react';

import { LocaleContext } from '@ninox/ninox-components/locale';

export function useSortingLabels() {
    const locale = useContext(LocaleContext);

    return useMemo(() => {
        return {
            ascending: locale.sortAscending,
            descending: locale.sortDescending,
            none: locale.sort
        };
    }, [locale]);
}
