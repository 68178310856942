import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../../shared/ErrorElement';
import { Layout } from './ManageCloud.layout';
import { loader } from './ManageCloud.loader';

export const ManageCloud = {
    element: <Layout />,
    loader,
    id: 'ManageCloud',
    shouldRevalidate: () => false,
    children: [
        {
            index: true,
            path: '*',
            lazy: () => import(/* webpackChunkName: "OrderSummary" */ './OrderSummary')
        },
        {
            path: 'settings',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "Settings" */ './Settings')
        },
        {
            path: 'custom-domain',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "CustomDomain" */ './CustomDomain')
        },
        {
            path: 'upgrade-subscription',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "UpgradeCloud" */ '../UpgradeCloud')
        },
        {
            path: 'renew-subscription',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "RenewCloud" */ '../RenewCloud')
        }
    ]
} satisfies RouteObject;
