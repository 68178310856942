import { Form as Base } from 'react-router-dom';
import styled from 'styled-components';

import { Spacing } from '@ninox/ninox-components/theme';

export const Form = styled(Base)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: ${Spacing.medium};
    width: 100%;
    max-width: 48rem;

    & :not(:focus-within)[aria-invalid='true'] {
        color: ${({ theme }) => theme.text.label};
        border-color: ${({ theme }) => theme.input.default.borderColor};
    }

    & :not(:focus-within) input:invalid {
        ${({ theme }) => theme.input.default};
    }

    & :not(:focus-within) input:invalid:hover {
        ${({ theme }) => theme.input.hover};
    }
`;
