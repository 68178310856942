import { json } from 'react-router-dom';

import { PublicClient } from '../api/public';
import type { LicenseResponse } from '../models/License';
import type { CompanyRole } from '../models/ManageClouds/CompanyInfo';
import type { UserPublic } from '../models/User.public';
import type { SessionUser } from './session.types';

export const sessionPublicCloud = async () => {
    let response: UserPublic | undefined = undefined;
    const license: LicenseResponse | undefined = undefined;
    const isRoot: boolean | undefined = undefined;
    let allowPrivateCloudUpgradeUpTo10Licenses: boolean | undefined = undefined;
    let isBusiness: boolean | undefined = undefined;
    let companyId: string | undefined = undefined;
    let companyRole: `${CompanyRole}` | undefined = undefined;
    let enableCompanyAccountSelfService: boolean | undefined = undefined;
    let enableDMSSalesAutomation: boolean | undefined = undefined;
    let expiryDate: string | undefined = undefined;
    let verified: boolean | undefined = undefined;
    const partial = await PublicClient.getSessionInfo();
    const userId = partial?.userId;

    if (userId) {
        const user = await PublicClient.getUser(userId);

        response = user;
        allowPrivateCloudUpgradeUpTo10Licenses = user?.allowPrivateCloudUpgradeUpTo10Licenses;
        isBusiness = user?.profile.isBusiness;
        companyId = user?.companyId;
        companyRole = user?.companyRole;
        enableCompanyAccountSelfService = user?.enableCompanyAccountSelfService;
        enableDMSSalesAutomation = user?.enableDMSSalesAutomation;
        expiryDate = user?.plan?.expiresAt;
        verified = user?.verified;
    }

    const session = {
        allowPrivateCloudUpgradeUpTo10Licenses,
        companyId,
        companyRole,
        email: response?.email || '',
        enableCompanyAccountSelfService,
        enableDMSSalesAutomation,
        expiryDate,
        id: response?.id || '',
        isBusiness,
        isRoot,
        language: response?.profile.locale && response.profile.locale !== 'auto' ? response.profile.locale : '',
        photo: response?.profile.photo ? `${process.env.IMG_ROOT}${response.profile.photo}` : '',
        verified
    } satisfies SessionUser;

    return json({ session, license });
};
