import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Layout } from './Users.layout';

export const Users = {
    element: <Layout />,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "UsersOverview" */ './UsersOverview')
        },
        {
            path: 'create',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "CreateUser" */ './CreateUser')
        },
        {
            path: ':userId',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "UpdateUser" */ './UpdateUser')
        }
    ]
} satisfies RouteObject;
