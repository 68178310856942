import { Color } from '@ninox/ninox-components/theme';

import type { Limits } from '../../models/Usage';
import type { UserPlanInfo } from '../../models/UserPlanInfo';
import { Tier } from '../../models/UserPlanInfo';

export const getColor = (value: number, maxValue: number) => {
    if (maxValue === 0) {
        return Color.accent['600'];
    }

    if (value / maxValue >= 0.9) {
        return Color.validation.attention['800'];
    }

    if (value / maxValue >= 0.75) {
        return Color.saturated.orange['600'];
    }

    return Color.saturated.teal['600'];
};

const _M = 1e6;
const _K = 1000;

export const displaySize = (
    size = 0,
    formatter: (value?: number | string, extension?: Intl.NumberFormatOptions) => string
) => {
    let unit = 'kilobyte';
    let value = size;

    if (size >= _M) {
        unit = 'gigabyte';
        value = size / _M;
    } else if (size >= _K) {
        unit = 'megabyte';
        value = size / _K;
    }

    return formatter(value, { unit });
};

export const getLimits = (plan: UserPlanInfo | undefined): Limits => {
    if (plan) {
        const { tier, name, collaborators } = plan;

        // starter
        if (tier === Tier.starter) {
            return {
                storage: collaborators * _M,
                records: 50000,
                apiCalls: 1500,
                emails: 150,
                carboneUsage: 100
            };
        }

        // professional
        if (tier === Tier.pro) {
            return {
                storage: collaborators * 2 * _M,
                apiCalls: 30000,
                emails: 3000,
                carboneUsage: 500
            };
        }

        // legacy
        if (tier === Tier.basic || (!tier && !name)) {
            return {
                storage: collaborators * 2 * _M,
                apiCalls: 0,
                emails: 0,
                carboneUsage: 0
            };
        }
    }

    // default
    return {
        storage: 0,
        apiCalls: 0,
        emails: 0,
        carboneUsage: 0
    };
};
