import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

import { PublicClient } from '../../../api/public';
import type { Pricing, PricingParams } from '../../../models/ManageClouds/Pricing';

export type ActionData = {
    update: Pricing;
};

export const action = async function ({ request }) {
    const { state, orderType } = await request.json();
    const featuresArr = Object.keys(state.features).filter((key) => state.features[key as keyof typeof state.features]);

    let params = {
        companyId: state.companyId,
        domain: state.subDomain ? `${state.subDomain}.${state.ninoxDomain}` : state.ninoxDomain,
        hostingProvider: state.hostingProvider,
        numberOfLicenses: state.maxUsers || state.numberOfLicenses,
        features: featuresArr,
        countryCode: state.countryCode || 'DE',
        startDate: state.startDate || new Date().toISOString(),
        orderType: orderType
    } as PricingParams;

    if (state.numberOfHrLicenses ?? 0 > 0) {
        params = { ...params, numberOfHrLicenses: state.numberOfHrLicenses } satisfies PricingParams;
    }

    if (state.features.DMS) {
        params = { ...params, dmsStorageUnitPerGB: state.dmsStorageUnitPerGB } satisfies PricingParams;
    }

    const response = await PublicClient.calculatePricing(params);

    if (response) {
        return json(response);
    }
} satisfies ActionFunction;
