export async function request<Params extends Record<string, any> | undefined = any, Response = any>(
    url: string,
    method = 'GET',
    data = {} as Params,
    options: RequestInit = {}
): Promise<Response | undefined> {
    let response;

    if (data instanceof FormData) {
        response = await fetch(url, {
            method,
            credentials: 'include',
            body: data,
            ...options
        });
    } else if (method === 'PUT' || method === 'POST' || method === 'DELETE' || method === 'PATCH') {
        response = await fetch(url, {
            method,
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            ...options
        });
    } else {
        response = await fetch(url, {
            method,
            credentials: 'include',
            ...options
        });
    }

    if (!response.ok) {
        throw response;
    }

    if (response.headers.get('content-length') === '0') {
        return;
    }

    const cloned = response.clone();

    try {
        return await response.json();
    } catch (error) {
        const message = await cloned.text();

        return {
            error,
            message: message || `${error}`
        } as Response;
    }
}
