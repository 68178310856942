import { useContext, useEffect, useMemo, useState } from 'react';

import { SessionContext } from '../../../../session/session.context';
import { initialContext } from './OrderCloud.context';
import type { OrderContextType, OrderState } from './OrderCloud.types';

export const useOrderCloud = () => {
    const [state, setState] = useState(initialContext.state);
    const { user } = useContext(SessionContext);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            orderedBy: user.id,
            email: user.email
        }));
    }, [user]);

    const actions = useMemo(() => {
        return {
            resetOrder: () => {
                setState(() => ({ ...initialContext.state, orderedBy: user.id, email: user.email }));
            },
            updateOrder: (next: Partial<OrderState>) => {
                setState((prev) => ({ ...prev, ...next }));
            }
        };
    }, []);

    const store = useMemo<OrderContextType>(
        () => ({
            state,
            actions
        }),
        [state, actions]
    );

    return store;
};
