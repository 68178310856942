import React from 'react';

import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { Account } from './pages/PublicCloud/Account';
import { ContactSales } from './pages/PublicCloud/ContactSales';
import { Integrations } from './pages/PublicCloud/Integrations';
import { ManageClouds } from './pages/PublicCloud/ManageClouds';
import { Profile } from './pages/PublicCloud/Profile';
import { Security } from './pages/PublicCloud/Security';
import { Subscriptions } from './pages/PublicCloud/Subscriptions';
import { SessionRoute } from './session/session.route';
import { ErrorElement } from './shared/ErrorElement';
import { Layout } from './shared/Layout';
import { Page } from './shared/styles/Page';
import { TopNav } from './shared/TopNav';

export const PublicCloud = [
    SessionRoute(true, [
        {
            element: <Layout isPublic />,
            errorElement: (
                <Page>
                    <TopNav isPublic />
                    <ErrorElement centered />
                </Page>
            ),
            children: [
                {
                    path: 'profile/*',
                    children: [Profile]
                },
                {
                    path: 'security/*',
                    children: [Security]
                },
                {
                    path: 'integrations/*',
                    children: [Integrations]
                },
                {
                    path: 'subscriptions/*',
                    children: [Subscriptions]
                },
                {
                    path: 'manage-clouds/*',
                    children: [ManageClouds]
                },
                {
                    path: '*',
                    element: <Navigate to="profile" />
                }
            ]
        }
    ]),
    {
        path: 'contact-sales',
        element: <ContactSales />,
        errorElement: <ErrorElement />
    },
    {
        path: 'account/*',
        children: [Account]
    }
] satisfies RouteObject[];
