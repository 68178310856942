import React from 'react';

import { Outlet } from 'react-router-dom';

import { Main } from '../../../shared/styles/Main';
import { Page } from '../../../shared/styles/Page';
import { TopNav } from '../../../shared/TopNav';

export const Layout = () => {
    return (
        <Page>
            <TopNav withoutMenus />
            <Main>
                <Outlet />
            </Main>
        </Page>
    );
};
