import React, { useCallback, useContext, useMemo } from 'react';

import { NavLink, Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@ninox/ninox-components/lib/Button';
import { LocaleContext } from '@ninox/ninox-components/locale';
import { startViewTransition } from '@ninox/ninox-components/utils/startViewTransition';

import { CompanyRole } from '../../../../models/ManageClouds/CompanyInfo';
import { SessionContext } from '../../../../session/session.context';
import type { NavRoute } from '../../../../shared/Nav';
import { TabNav } from '../../../../shared/TabNav';
import { Content } from './ManageCloud.styles';
import type { LoaderData } from './ManageCloud.loader';

export default Layout;
export function Layout() {
    const locale = useContext(LocaleContext);
    const data = useLoaderData() as LoaderData;
    const navigate = useNavigate();
    const { user } = useContext(SessionContext);
    const params = useParams();
    const onOrderPage = useMemo(
        () => params['*'] === 'upgrade-subscription' || params['*'] === 'renew-subscription',
        [params]
    );
    const onBack = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();

            startViewTransition(() => {
                navigate('..');
            });
        },
        [navigate]
    );

    const isAdmin = useMemo(
        () => user.companyRole === CompanyRole.admin || user.companyRole === CompanyRole.superAdmin,
        [user]
    );

    const isProvisioned = !!data.order.license?.maxUsers;

    const subRoutes = useMemo(
        () =>
            [
                {
                    end: true,
                    value: '',
                    label: locale.subscriptions,
                    trackKey: 'PrivateCloudsSubscriptions'
                },
                isAdmin &&
                    isProvisioned && {
                        value: 'settings',
                        label: locale.settings,
                        trackKey: 'PrivateCloudsSettings'
                    },
                isAdmin &&
                    isProvisioned && {
                        value: 'custom-domain',
                        label: locale.customDomains,
                        trackKey: 'PrivateCloudsCustomDomain'
                    }
            ].filter(Boolean) as NavRoute[],
        []
    );

    return (
        <>
            <Button
                as={({ className, children }) => (
                    <NavLink className={className} to=".." onClick={onBack}>
                        {children}
                    </NavLink>
                )}
                emphasis="tertiary"
                icon="chevron_left"
            >
                {locale.back}
            </Button>

            <Content>
                {onOrderPage ? (
                    <Outlet />
                ) : (
                    <TabNav routes={subRoutes} showTitles={false}>
                        <Outlet />
                    </TabNav>
                )}
            </Content>
        </>
    );
}
