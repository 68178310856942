import React, { useContext, useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { LocaleContext } from '@ninox/ninox-components/locale';

import { SessionContext } from '../../../session/session.context';
import type { NavRoute } from '../../../shared/Nav';
import { TabNav } from '../../../shared/TabNav';

export const Layout = () => {
    const locale = useContext(LocaleContext);
    const { user } = useContext(SessionContext);

    const tabRoutes = useMemo(
        () =>
            [
                {
                    value: '',
                    end: true,
                    label: locale.profilePersonalInfo,
                    description: locale.profilePersonalSummary,
                    trackKey: 'PersonalInfo'
                },
                user.isBusiness && {
                    value: 'company',
                    label: locale.companyInfo,
                    description: locale.profileCompanySummary,
                    trackKey: 'Company'
                },
                {
                    value: 'communication-preferences',
                    label: locale.profileMessages,
                    description: locale.profileMessagesSummary,
                    trackKey: 'CommunicationPreferences'
                },
                {
                    value: 'display-settings',
                    label: locale.profileDisplaySettings,
                    description: locale.profileDisplaySettingsSummary,
                    trackKey: 'DisplaySettings'
                }
            ].filter(Boolean) as NavRoute[],
        [locale, user.isBusiness]
    );

    return (
        <TabNav routes={tabRoutes}>
            <Outlet />
        </TabNav>
    );
};
