import type { ActionFunction } from 'react-router-dom';
import { json } from 'react-router-dom';

import { fromFormData } from '@ninox/ninox-components/utils/formData';

import { PublicClient } from '../../../../api/public';

export type ActionResult = {
    available: boolean;
    domain?: string;
};

export const action = async function ({ request }) {
    const formData = await request.formData();
    const { subDomain, ninoxDomain } = fromFormData(formData);

    if (!subDomain || !ninoxDomain) return json({ available: false });

    try {
        const res = await PublicClient.checkDomain(`${subDomain}.${ninoxDomain}`);

        if (res) {
            return json(res);
        }
    } catch {
        return json({ available: false, domain: 'invalid' });
    }
} satisfies ActionFunction;
