import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Layout } from './Profile.layout';

export const Profile = {
    element: <Layout />,
    errorElement: <ErrorElement />,
    children: [
        {
            index: true,
            path: '*',
            lazy: () => import(/* webpackChunkName: "Personal" */ './Personal')
        },
        {
            path: 'company',
            lazy: () => import(/* webpackChunkName: "Company" */ './Company')
        },
        {
            path: 'communication-preferences',
            lazy: () => import(/* webpackChunkName: "Communication" */ './Communication')
        },
        {
            path: 'display-settings',
            lazy: () => import(/* webpackChunkName: "Display" */ './Display')
        }
    ]
} satisfies RouteObject;
