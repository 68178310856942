import { toURLQueryString } from '@ninox/ninox-components/utils/toURLQueryString';

import { request } from '../utils/request';
import { methods } from './api.types';

import type { AcceptInvitationResponse, VerifyEmailResponse } from '../models/Accounts';
import type { ApiKey } from '../models/ApiKey';
import type { ChangePasswordParams, ChangePasswordResponse } from '../models/ChangePassword';
import type { ChangeCollaboratorParams, CollaboratorResponse } from '../models/Collaborators';
import type { CountriesResponse } from '../models/Countries';
import type { DeleteUserParams, DeleteUserRequestParams, DeleteUserResponse } from '../models/DeleteUser';
import type { DeployStatusResponse } from '../models/DeployStatus';
import type { CompanyInfo, UpdateCompanyResponse } from '../models/ManageClouds/CompanyInfo';
import type { CreateOrderParams, CreateOrderResponse } from '../models/ManageClouds/CreateCloudOrder';
import type { CreateCompanyParams, CreateCompanyResponse } from '../models/ManageClouds/CreateCompany';
import type {
    CustomDomain,
    CustomDomainRequest,
    CustomDomainsResponse,
    RefreshCustomDomainRequest,
    RefreshCustomDomainResponse
} from '../models/ManageClouds/CustomDomains';
import type {
    ExternalDeployRequest,
    ExternalDeployResponse,
    ExternalMaintenance,
    ExternalRestartResponse
} from '../models/ManageClouds/ExternalClouds';
import type { OrderDetailItem, OrderOverviewItem } from '../models/ManageClouds/Orders';
import type { Pricing, PricingParams } from '../models/ManageClouds/Pricing';
import type { ProvidersResponse } from '../models/ManageClouds/Providers';
import type { RenewOrderParams, RenewOrderResponse } from '../models/ManageClouds/RenewCloudOrder';
import type { UpgradeOrderParams, UpgradeOrderResponse } from '../models/ManageClouds/UpgradeCloudOrder';
import type { ResetPasswordParams, ResetPasswordResponse } from '../models/ResetPassword';
import type { SessionInfoResponse } from '../models/SessionInfo';
import type {
    SubscriptionLinkParams,
    SubscriptionLinkRenewParams,
    SubscriptionLinkResponse,
    SubscriptionLinkUpgradeParams
} from '../models/SubscriptionsLink';
import type { SubscriptionSalesParams } from '../models/SubscriptionsSales';
import type { UsageData } from '../models/Usage';
import type { UpdateUserData, UserPublic } from '../models/User.public';
import type { UserPaymentInfo } from '../models/UserPaymentInfo';
import type { UserPlanInfo } from '../models/UserPlanInfo';
import type { VersionResponse } from '../models/Version';

const API_PATH = process.env.API_PATH || '';
const BASE_NAME = process.env.BASE_NAME || '/';

/**
 * Public connects to the public cloud's api
 */
export const PublicClient = {
    // fetch user
    getSessionInfo() {
        return request<undefined, SessionInfoResponse>(`${API_PATH}/api/v3/sessions/info`);
    },
    getUser(userId: string) {
        return request<undefined, UserPublic>(`${API_PATH}/api/v4/user/${userId}`);
    },

    // Profile
    updateUserProfile(userId: string, data: UpdateUserData) {
        return request(`${API_PATH}/api/v4/user/${userId}`, methods.PUT, data);
    },
    updateImage(userId: string, data: FormData) {
        return request<FormData, UserPublic>(`${API_PATH}/api/v4/user/${userId}/photo`, methods.POST, data);
    },

    // Security
    updatePassword(userId: string, data: ChangePasswordParams) {
        return request<ChangePasswordParams, ChangePasswordResponse>(
            `${API_PATH}/api/v4/user/${userId}/password`,
            methods.PUT,
            data
        );
    },

    getDeletionCode(data: DeleteUserRequestParams) {
        return request<DeleteUserRequestParams>(`${API_PATH}/api/v4/account/deletion-request`, methods.POST, data);
    },
    deleteUser(userId: string, data: DeleteUserParams) {
        return request<DeleteUserParams, DeleteUserResponse>(
            `${API_PATH}/api/v4/account/${userId}`,
            methods.DELETE,
            data
        );
    },

    // Integrations
    getApiKeys(userId: string) {
        return request<undefined, ApiKey[]>(`${API_PATH}/api/v4/user/${userId}/tokens`);
    },
    createApiKey(userId: string) {
        return request(`${API_PATH}/api/v4/user/${userId}/tokens`, methods.POST);
    },
    deleteApiKey(userId: string, token: string) {
        return request(`${API_PATH}/api/v4/user/${userId}/tokens/${token}`, methods.DELETE);
    },

    // Subscriptions
    getUsageData(userId: string) {
        return request<undefined, UsageData>(`${API_PATH}/api/v4/user/${userId}/db-stats/teams`);
    },
    getPlanData(userId: string) {
        return request<undefined, UserPlanInfo>(`${API_PATH}/api/v4/user/${userId}/planInfo`);
    },
    generatePaymentLink(userId: string, params: SubscriptionLinkParams) {
        const queryString = toURLQueryString(params);

        return request<undefined, SubscriptionLinkResponse>(
            `${API_PATH}/api/v4/user/${userId}/payment-link?${queryString}`
        );
    },
    generatePaymentLinkUpgrade(userId: string, params: SubscriptionLinkUpgradeParams) {
        const queryString = toURLQueryString(params);

        return request<undefined, SubscriptionLinkResponse>(
            `${API_PATH}/api/v4/user/${userId}/payment-upgrade?${queryString}`
        );
    },
    generatePaymentLinkRenew(userId: string, params: SubscriptionLinkRenewParams) {
        const queryString = toURLQueryString(params);

        return request<undefined, SubscriptionLinkResponse>(
            `${API_PATH}/api/v4/user/${userId}/payment-renew?${queryString}`
        );
    },
    subscriptionSalesSupportRenew(data: SubscriptionSalesParams) {
        return request<SubscriptionSalesParams>(`${API_PATH}/api/v4/leads/subscription-renewal`, methods.POST, data);
    },
    subscriptionSalesSupportUpgrade(data: SubscriptionSalesParams) {
        return request<SubscriptionSalesParams>(`${API_PATH}/api/v4/leads/subscription-upgrade`, methods.POST, data);
    },
    getPayments(userId: string) {
        return request<undefined, UserPaymentInfo[]>(`${API_PATH}/api/v4/user/${userId}/payments`);
    },
    getPlanInfo(userId: string) {
        return request<undefined, UserPlanInfo>(`${API_PATH}/api/v4/user/${userId}/planinfo`);
    },

    // Accounts
    acceptInvitation(invitation: string) {
        const queryString = toURLQueryString({
            invitation,
            callbackUrl: `${window.location.origin}${BASE_NAME}account/accept-invitation?invitation=${invitation}`
        });

        return request<undefined, AcceptInvitationResponse>(`${API_PATH}/api/v4/en/accept-invitation?${queryString}`);
    },
    verifyResetPasswordToken(token: string) {
        const queryString = toURLQueryString({ token });

        return request<undefined, VerifyEmailResponse>(
            `${API_PATH}/api/v4/credentials/verify-reset-password-token?${queryString}`
        );
    },
    resetPassword(data: ResetPasswordParams) {
        return request<ResetPasswordParams, ResetPasswordResponse>(
            `${API_PATH}/api/v4/credentials/resetPassword`,
            methods.POST,
            data
        );
    },

    verifyEmail(token: string) {
        const queryString = toURLQueryString({ token });

        return request<undefined, VerifyEmailResponse>(`${API_PATH}/api/v4/credentials/verify-email?${queryString}`);
    },

    // Manage Clouds
    getCompanyInfo() {
        return request<undefined, CompanyInfo>(`${API_PATH}/api/v4/company`);
    },
    updateCompanyInfo(data: CompanyInfo) {
        return request<CompanyInfo, UpdateCompanyResponse>(`${API_PATH}/api/v4/company`, methods.PATCH, data);
    },
    getOrders() {
        return request<undefined, OrderOverviewItem[]>(`${API_PATH}/api/antcore/external/hosts`);
    },
    getOrder(domain: string) {
        return request<undefined, OrderOverviewItem>(`${API_PATH}/api/antcore/external/hosts/${domain}`);
    },
    getOrderHistory(domain: string) {
        const queryString = toURLQueryString({ domain });

        return request<undefined, OrderDetailItem[]>(`${API_PATH}/api/orders-service/orders?${queryString}`);
    },
    updateExternalMaintenance(domain: string, data: ExternalMaintenance) {
        return request<ExternalMaintenance, ExternalMaintenance>(
            `${API_PATH}/api/antcore/external/hosts/${domain}`,
            methods.PATCH,
            data
        );
    },
    restartExternal(domain: string) {
        return request<undefined, ExternalRestartResponse>(
            `${API_PATH}/api/antcore/external/hosts/${domain}/restart`,
            methods.POST
        );
    },
    getExternalVersions() {
        return request<undefined, VersionResponse>(`${API_PATH}/api/antcore/external/versions`);
    },
    deployExternal(data: ExternalDeployRequest) {
        return request<ExternalDeployRequest, ExternalDeployResponse>(
            `${API_PATH}/api/antcore/external/versions/deploy`,
            methods.POST,
            data
        );
    },
    getExternalStatus(domain: string) {
        return request<undefined, DeployStatusResponse>(
            `${API_PATH}/api/antcore/external/versions/${domain}/deployment/status`
        );
    },
    getCustomDomains(host: string) {
        const queryString = toURLQueryString({ host });

        return request<undefined, CustomDomainsResponse>(
            `${API_PATH}/api/antcore/external/custom-domain?${queryString}`
        );
    },
    addCustomDomain(host: string, data: CustomDomainRequest) {
        const queryString = toURLQueryString({ host });

        return request<CustomDomainRequest, CustomDomainsResponse>(
            `${API_PATH}/api/antcore/external/custom-domain?${queryString}`,
            methods.POST,
            data
        );
    },
    deleteCustomDomain(hostDomain: string, customDomain: string) {
        return request<CustomDomain, undefined>(
            `${API_PATH}/api/antcore/external/custom-domain/${hostDomain}/${customDomain}`,
            methods.DELETE
        );
    },
    refreshCustomDomain(data: RefreshCustomDomainRequest) {
        return request<RefreshCustomDomainRequest, RefreshCustomDomainResponse>(
            `${API_PATH}/api/antcore/external/custom-domain`,
            methods.PUT,
            data
        );
    },
    getProviders() {
        return request<undefined, ProvidersResponse>(`${API_PATH}/api/antcore/external/clouds/providers`);
    },
    getProviderRegions(provider: string) {
        return request<undefined, string[]>(`${API_PATH}/api/antcore/external/clouds/${provider}/regions/list`);
    },
    checkDomain(domain: string) {
        return request<undefined, { available: boolean }>(
            `${API_PATH}/api/antcore/external/clouds/route53/check/${domain}`
        );
    },
    getCountries() {
        return request<undefined, CountriesResponse>(`${API_PATH}/api/orders-service/countries`);
    },
    validateVat(countryCode: string, vatNumber: string) {
        const queryString = toURLQueryString({ countryCode, vatNumber });

        return request<undefined, { isValid: boolean }>(`${API_PATH}/api/orders-service/vat/validate?${queryString}`);
    },
    calculatePricing(data: PricingParams) {
        return request<PricingParams, Pricing>(
            `${API_PATH}/api/orders-service/pricing/calculate-pricing`,
            methods.POST,
            data
        );
    },
    submitCreateCloudOrder(order: CreateOrderParams) {
        return request<CreateOrderParams, CreateOrderResponse>(
            `${API_PATH}/api/orders-service/orders/cloud`,
            methods.POST,
            order
        );
    },
    submitUpgradeCloudOrder(order: UpgradeOrderParams) {
        return request<UpgradeOrderParams, UpgradeOrderResponse>(
            `${API_PATH}/api/orders-service/orders/upgrade-license`,
            methods.POST,
            order
        );
    },
    submitRenewCloudOrder(order: RenewOrderParams) {
        return request<RenewOrderParams, RenewOrderResponse>(
            `${API_PATH}/api/orders-service/orders/renew-license`,
            methods.POST,
            order
        );
    },
    createCompany(data: CreateCompanyParams) {
        return request<CreateCompanyParams, CreateCompanyResponse>(`${API_PATH}/api/v4/company`, methods.POST, data);
    },
    getCollaborators(userId: string) {
        return request<undefined, CollaboratorResponse>(`${API_PATH}/api/v4/user/${userId}/collaborators`);
    },
    removeCompanyMember(userId: string) {
        return request(`${API_PATH}/api/v4/company/removeMember/${userId}`, methods.DELETE);
    },
    addCompanyMember(data: ChangeCollaboratorParams) {
        return request<ChangeCollaboratorParams>(`${API_PATH}/api/v4/company/addMember`, methods.PATCH, data);
    },
    updateCompanyMember(data: ChangeCollaboratorParams) {
        return request<ChangeCollaboratorParams>(`${API_PATH}/api/v4/company/updateMember`, methods.PATCH, data);
    },

    // Logout
    logout() {
        return request(`${API_PATH}/api/v3/credentials/signout`, methods.POST);
    }
};
