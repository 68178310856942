import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { CreateCloud } from './CreateCloud';
import { ManageCloud } from './ManageCloud';
import { Layout } from './ManageClouds.layout';
import { action as actionPricing } from './CalculatePricing.action';
import { action as actionCheckVat } from './CheckVat.action';

export const ManageClouds = {
    element: <Layout />,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "CloudOverview" */ './Overview')
        },
        {
            path: ':domain/*',
            children: [ManageCloud]
        },
        {
            path: 'order/*',
            children: [CreateCloud]
        },
        {
            path: 'company-info',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "CompanyInfo" */ './CompanyInfo')
        },
        {
            path: 'company-members',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "CompanyMembers" */ './CompanyMembers')
        },
        {
            path: 'calculate-pricing',
            action: actionPricing
        },
        {
            path: 'check-vat',
            action: actionCheckVat
        }
    ]
} satisfies RouteObject;
