import type { LoaderFunction } from 'react-router-dom';
import { defer } from 'react-router-dom';

import { PrivateClient } from '../../../../api/private';

export type LoaderData = {
    databaseNameById: {
        [dbId: string]: string;
    };
};

const cache: {
    [workspaceId: string]: {
        [dbId: string]: string;
    };
} = {};

export const loader: LoaderFunction = async ({ params }) => {
    const workspaceId = params.workspaceId || '';

    if (!(workspaceId in cache)) {
        cache[workspaceId] = {};

        const response = await PrivateClient.getDatabases(workspaceId);

        if (response) {
            for (const { id, name } of response) {
                cache[workspaceId][id] = name;
            }
        }
    }

    return defer({ databaseNameById: cache[workspaceId] || {} });
};
