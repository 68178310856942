import type { RouteObject } from 'react-router-dom';
import { defer } from 'react-router-dom';

import { PublicClient } from '../../api/public';
import type { UsageData } from '../../models/Usage';
import type { UserPlanInfo } from '../../models/UserPlanInfo';

export type LoaderData = {
    usageData: Promise<UsageData>;
    planInfo: Promise<UserPlanInfo>;
};

let userId: string;

export const loader: RouteObject['loader'] = async () => {
    if (!userId) {
        const user = await PublicClient.getSessionInfo();

        if (!user) {
            throw user;
        }

        userId = user.userId;
    }

    const usageData = PublicClient.getUsageData(userId);
    const planInfo = PublicClient.getPlanInfo(userId);

    return defer({ usageData, planInfo });
};
