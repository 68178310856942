import React from 'react';

import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { Layout } from './Authentication.layout';

export const Authentication = {
    element: <Layout />,
    id: 'authentication',
    children: [
        {
            path: 'basic',
            index: true,
            lazy: () => import(/* webpackChunkName: "Basic" */ './Strategies/Basic')
        },
        {
            path: 'open-id',
            lazy: () => import(/* webpackChunkName: "OpenId" */ './Strategies/OpenId')
        },
        {
            path: 'saml',
            lazy: () => import(/* webpackChunkName: "Saml" */ './Strategies/Saml')
        },
        {
            path: 'active-directory',
            lazy: () => import(/* webpackChunkName: "ActiveDirectory" */ './Strategies/ActiveDirectory')
        },
        {
            path: '*',
            element: <Navigate replace to="basic" />
        }
    ]
} satisfies RouteObject;
