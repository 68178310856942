import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';

import { SideNav } from '../SideNav';
import { Main, Relative } from '../styles/Main';
import { Page } from '../styles/Page';
import { Scroll } from '../styles/Scroll';
import { TopNav } from '../TopNav';

type Props = {
    isPublic?: boolean;
};

export const Layout = ({ isPublic = false }: Props) => {
    const [showSidebar, setShowSidebar] = useState(true);

    return (
        <Page>
            <TopNav isPublic={isPublic} isSidebarOpened={showSidebar} setIsOpened={setShowSidebar} />
            <Main>
                <SideNav isOpened={showSidebar} isPublic={isPublic} setIsOpened={setShowSidebar}>
                    <Relative>
                        <Scroll>
                            <Outlet />
                        </Scroll>
                    </Relative>
                </SideNav>
            </Main>
        </Page>
    );
};
