import styled from 'styled-components';

import { SimpleButton } from '@ninox/ninox-components/lib/Button';
import { BorderWidth, FontSize, FontWeight, Size, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.header`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    align-self: stretch;

    height: ${Size.l};
    width: 100%;
    padding: 0 ${Spacing.large};
    gap: ${Spacing.medium};

    contain: layout;

    ${({ theme }) => theme.nav.primary}

    border-style: solid;
    border-width: 0;
    border-bottom-width: ${BorderWidth.tiny};
`;

export const MainButton = styled(SimpleButton)`
    font-size: ${FontSize.large};
    font-weight: ${FontWeight.normal};
    gap: ${Spacing.x_small};
    border-width: 0;
    padding: ${Spacing.thin};

    flex: 1 1 0%;
    overflow: auto;
    scrollbar-width: none;

    transition: all 300ms ease;

    &&,
    &:visited {
        color: inherit;
    }

    &:focus-visible {
        text-decoration: underline;
    }
`;

export const Bold = styled.b`
    font-weight: ${FontWeight.bold};
`;

export const IconContainer = styled.div`
    display: grid;
    place-items: center;

    position: sticky;
    left: 0;

    height: ${Size.m};
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border-width: 0;
    box-sizing: border-box;

    color: ${({ theme }) => theme.icon.accent};
    background-color: ${({ theme }) => theme.nav.primary.backgroundColor};

    outline-offset: 0;
    outline-width: ${BorderWidth.tiny};
    outline-color: ${({ theme }) => theme.nav.primary.borderColor};
    outline-style: solid;
`;
