import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { loader as loaderDatabase } from './loader/database.loader';
import { loader as loaderEvents } from './loader/events.loader';
import { loader as loaderRestart } from './loader/restart.loader';
import { loader as loaderTeamMetrics } from './loader/teamMetrics.loader';
import { loader as loaderTransactions } from './loader/transactions.loader';
import { Layout } from './ProcessMonitor.layout';

const ProcessWorkspaceLayout = React.lazy(
    () => import(/* webpackChunkName: "ProcessWorkspace" */ './ProcessWorkspace/ProcessWorkspace.layout')
);

export const ProcessMonitor = {
    element: <Layout />,
    id: 'process-monitor',
    loader: loaderTeamMetrics,
    children: [
        {
            index: true,
            path: '*',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ProcessOverview" */ './ProcessOverview')
        },
        {
            path: ':workspaceId',
            element: <ProcessWorkspaceLayout />,
            errorElement: <ErrorElement />,
            children: [
                {
                    index: true,
                    loader: loaderDatabase,
                    lazy: () => import(/* webpackChunkName: "ProcessWorkspace" */ './ProcessWorkspace')
                },
                {
                    path: 'transactions',
                    loader: loaderTransactions
                },
                {
                    path: 'events/:reqId',
                    loader: loaderEvents
                }
            ]
        },
        {
            path: 'restart/:workspaceId',
            loader: loaderRestart,
            lazy: () => import(/* webpackChunkName: "RestartWorkspace" */ './RestartWorkspace')
        }
    ]
} satisfies RouteObject;
