import React from 'react';

import { SvgIcon, type SvgIconProps } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const ExpiredLink = ({ color, ...props }: SvgIconProps) => {
    return (
        <SvgIcon intrinsicSize={60} stroke="none" {...props}>
            <path
                d="M10.7191 21.1051C10.5758 21.1051 10.4309 21.0758 10.2926 21.0123L1.23488 16.8677C0.720465 16.6316 0.492557 16.0228 0.728604 15.5084C0.96465 14.994 1.57349 14.7677 2.08791 15.0021L11.1456 19.1467C11.66 19.3828 11.8879 19.9916 11.6519 20.506C11.4793 20.8837 11.1065 21.1051 10.7191 21.1051ZM15.3228 16.2344C15.0607 16.2344 14.7986 16.1351 14.5984 15.9333L7.86372 9.20023C7.46325 8.79977 7.46325 8.15023 7.86372 7.74977C8.26418 7.3493 8.91372 7.3493 9.31419 7.74977L16.0488 14.4844C16.4493 14.8849 16.4493 15.5344 16.0488 15.9349C15.8486 16.1335 15.5865 16.2344 15.3228 16.2344ZM20.1007 11.6877C19.7067 11.6877 19.3307 11.4581 19.1614 11.074L15.1323 1.90233C14.9044 1.38302 15.1405 0.77907 15.6581 0.551163C16.1774 0.323256 16.7814 0.559303 17.0093 1.07698L21.0384 10.2486C21.2663 10.7679 21.0302 11.3719 20.5126 11.5998C20.3791 11.66 20.2391 11.6877 20.1007 11.6877Z"
                fill={color}
            />
            <path
                d="M43.9284 59.5367C43.5344 59.5367 43.1584 59.3072 42.9891 58.923L38.96 49.7514C38.7321 49.2321 38.9681 48.6281 39.4858 48.4002C40.0035 48.1723 40.6091 48.4084 40.837 48.926L44.866 58.0977C45.094 58.617 44.8579 59.2209 44.3402 59.4488C44.2067 59.5091 44.0667 59.5367 43.9284 59.5367ZM51.4102 52.5514C51.1481 52.5514 50.886 52.4521 50.6858 52.2502L43.9512 45.5156C43.5507 45.1151 43.5507 44.4656 43.9512 44.0651C44.3516 43.6646 45.0012 43.6646 45.4016 44.0651L52.1363 50.7998C52.5367 51.2002 52.5367 51.8498 52.1363 52.2502C51.936 52.4505 51.6723 52.5514 51.4102 52.5514ZM58.337 45.0907C58.1937 45.0907 58.0488 45.0614 57.9105 44.9979L48.8528 40.8533C48.3384 40.6172 48.1105 40.0084 48.3465 39.4939C48.5826 38.9795 49.1914 38.7532 49.7058 38.9877L58.7635 43.1323C59.2779 43.3684 59.5058 43.9772 59.2698 44.4916C59.0988 44.8677 58.726 45.0907 58.337 45.0907Z"
                fill={color}
            />
            <path
                d="M17.3805 57.4188C17.2958 57.4188 17.2112 57.4188 17.1265 57.4172C13.314 57.3521 9.70488 55.8072 6.96512 53.0691L6.91953 53.0235C1.24953 47.3584 1.08837 38.286 6.56302 32.7984L15.7656 23.573C16.166 23.1726 16.8156 23.1709 17.216 23.5714C17.6165 23.9719 17.6181 24.6214 17.2177 25.0219L8.01512 34.2472C3.33977 38.934 3.4993 46.7056 8.37 51.573L8.41558 51.6186C10.7777 53.9791 13.8853 55.3107 17.164 55.366C20.4279 55.4214 23.4688 54.207 25.7267 51.9442L34.9293 42.7188C35.3298 42.3184 35.9793 42.3167 36.3798 42.7172C36.7802 43.1177 36.7819 43.7672 36.3814 44.1677L27.1788 53.393C24.5807 55.9944 21.11 57.4188 17.3805 57.4188ZM43.6549 36.58C43.3928 36.58 43.1307 36.4807 42.9305 36.2805C42.53 35.88 42.5284 35.2305 42.9288 34.83L52.1314 25.6047C56.8067 20.9179 56.6472 13.1463 51.7765 8.27884L51.7293 8.23163C49.3672 5.87116 46.2612 4.54116 42.9826 4.48418C39.7202 4.43209 36.6777 5.64325 34.4198 7.90605L25.2172 17.1314C24.8167 17.5319 24.1672 17.5335 23.7667 17.133C23.3663 16.7326 23.3646 16.083 23.7651 15.6826L32.9677 6.45721C35.6228 3.79558 39.1895 2.36628 43.0184 2.43302C46.8309 2.49814 50.44 4.04302 53.1798 6.78116L53.227 6.82837C58.897 12.4935 59.0565 21.5658 53.5835 27.0535L44.3809 36.2788C44.1807 36.4791 43.9186 36.58 43.6549 36.58Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

ExpiredLink.defaultProps = {
    size: Size.xxl,
    color: Color.accent['700']
};
