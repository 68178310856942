import { json } from 'react-router-dom';

import { PrivateClient } from '../api/private';
import type { LicenseResponse } from '../models/License';
import type { CompanyRole } from '../models/ManageClouds/CompanyInfo';
import type { UserPrivate } from '../models/User.private';
import type { SessionUser } from './session.types';

export const sessionPrivateCloud = async () => {
    let response: UserPrivate | undefined = undefined;
    let license: LicenseResponse | undefined = undefined;
    let isRoot: boolean | undefined = undefined;
    const isBusiness: boolean | undefined = undefined;
    const companyId: string | undefined = undefined;
    const companyRole: `${CompanyRole}` | undefined = undefined;
    let expiryDate: string | undefined = undefined;

    response = await PrivateClient.getSessionInfo();
    license = await PrivateClient.getLicense();
    isRoot = response?.isRoot;
    expiryDate = license?.license.expiryDate || undefined;

    const session = {
        companyId,
        companyRole,
        email: response?.email || '',
        expiryDate,
        id: response?.id || '',
        isBusiness,
        isRoot,
        language: response?.profile.locale && response.profile.locale !== 'auto' ? response.profile.locale : '',
        photo: response?.profile.photo ? `${process.env.IMG_ROOT}${response.profile.photo}` : ''
    } satisfies SessionUser;

    return json({ session, license });
};
