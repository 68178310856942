import type { LoaderFunction } from 'react-router-dom';
import { defer, redirect } from 'react-router-dom';

import { PublicClient } from '../../../../api/public';
import type { OrderDetailItem, OrderOverviewItem } from '../../../../models/ManageClouds/Orders';

export type LoaderData = {
    order: OrderOverviewItem;
    history: OrderDetailItem[];
};

export const loader = (async ({ params }) => {
    const domain = params.domain;

    if (!domain) {
        return redirect('..');
    }

    const order = await PublicClient.getOrder(domain);

    if (!order) {
        return redirect('..');
    }

    const history = await PublicClient.getOrderHistory(domain);

    return defer({ history, order });
}) satisfies LoaderFunction;
