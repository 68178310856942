import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Icon } from '@ninox/ninox-components/lib/Icon';
import { Size } from '@ninox/ninox-components/theme';
import type { PropsFromStyledComponent } from '@ninox/ninox-components/utils/types';

import { Container, Image, Placeholder } from './Avatar.styles';

const defaultImage =
    process.env.MODE !== 'production' ? 'https://upload.wikimedia.org/wikipedia/en/b/b5/Rowlf_the_Dog.jpg' : '';
const Fallback = <Icon icon={{ icon: 'person', size: Size.sm }} />;

type Props = Omit<PropsFromStyledComponent<typeof Container>, 'size'> & {
    src: string | undefined;
    fallback?: React.ReactNode;
    placeholder?: string;
    size?: string;
};

export const Avatar = React.forwardRef<HTMLDivElement, Props>(function Avatar(
    { src = defaultImage, children, fallback = Fallback, size = Size.m, ...props },
    ref
) {
    const [isPlaceholder, setIsPlaceholder] = useState(!src);
    const placeholder = useMemo<React.ReactNode>(
        () => (typeof fallback === 'string' ? fallback[0]?.toUpperCase?.() : fallback),
        [fallback]
    );

    const onError = useCallback<React.ReactEventHandler<HTMLImageElement>>(() => {
        setIsPlaceholder(true);
    }, []);

    useLayoutEffect(() => {
        setIsPlaceholder(!src);
    }, [src]);

    return (
        <Container {...props} ref={ref} size={size}>
            {isPlaceholder ? (
                <Placeholder>{placeholder}</Placeholder>
            ) : (
                <Image alt="avatar" src={src} onError={onError} />
            )}
            {children}
        </Container>
    );
});
