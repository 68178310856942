import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Layout } from './Workspaces.layout';
import { action } from './WorkspaceUpdate/WorkspaceUpdate.action';

export const Workspaces = {
    element: <Layout />,
    children: [
        {
            index: true,
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "WorkspaceOverview" */ './WorkspaceOverview')
        },
        {
            path: 'create',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "WorkspaceCreate" */ './WorkspaceCreate')
        },
        {
            path: ':workspace',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "WorkspaceUpdate" */ './WorkspaceUpdate')
        },
        {
            path: ':workspace/update',
            action
        }
    ]
} satisfies RouteObject;
